import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegistrationForm from '../Components/Registration & Login/RegistrationForm';
import LoginSelect from '../Components/Registration & Login/LoginSelect';
import FunScholarAdmin from '../Components/Registration & Login/LoginTypes/FunScholarAdmin';
import AdminDashboard from '../Components/Dashboard/AdminDashboard';
import Board from '../Components/Dashboard/SubMenu/Board';
import Centre from '../Components/Dashboard/SubMenu/Centre';
import Subject from '../Components/Dashboard/SubMenu/Subject';
import Class from '../Components/Dashboard/SubMenu/Class';
import VideoPlayer from '../Components/VideoPlayer';
import Home from '../Components/Dashboard/Home';
// import PrivateRoute from './PrivateRoute';

const RoutesConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/registrationform" element={<RegistrationForm />} />
        <Route path="/admindashboard" element={<AdminDashboard />} />
        <Route path="/home" element={<Home />} />
        <Route path="/loginselect" element={<LoginSelect />} />
        <Route path="/funscholaradmin" element={<FunScholarAdmin />} />
        <Route path="/videoplayer" element={<VideoPlayer />} />
        <Route path="/board" element={<Board />} />
        <Route path="/centre" element={<Centre />} />
        <Route path="/subject" element={<Subject />} />
        <Route path="/class" element={<Class />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesConfig;

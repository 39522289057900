import React from "react";
import RoutesConfig from "./All Routing/RoutesConfig";
const App = () => {
  
  return(
    <div className="App">
      <RoutesConfig/>
    </div>
  )
}
export default App;

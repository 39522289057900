import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../ALL Css/ALL Dashboard CSS/AdminDashboard.css';

const AdminDashboard = () => {
  const [menuData, setMenuData] = useState([]);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [heading, setHeading] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [isProfilePopupVisible, setIsProfilePopupVisible] = useState(false);
  
  const navigate = useNavigate();
  const profilePopupRef = useRef(null);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch('/menus');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setMenuData(data);
      } catch (error) {
        setError('Error fetching menu: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMenuData();
    const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
    setUserDetails(storedUserDetails);
  }, []);

  const toggleSubmenu = (menuCode) => {
    setExpandedMenu((prev) => (prev === menuCode ? null : menuCode));
  };

  const renderMenuItems = () => {
    return menuData
      .filter((item) => item.ParentCode === 0.00000)
      .map((menu) => (
        <div key={menu.MenuCode}>
          <div
            onClick={() => toggleSubmenu(menu.MenuCode)}
            style={{ cursor: 'pointer', fontWeight: 'bold' }}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => e.key === 'Enter' && toggleSubmenu(menu.MenuCode)}
          >
            {menu.MenuName}
          </div>
          {expandedMenu === menu.MenuCode && renderSubmenuItems(menu.MenuCode)}
        </div>
      ));
  };

  const renderSubmenuItems = (parentMenuCode) => {
    return menuData
      .filter((submenu) => submenu.ParentCode === parentMenuCode)
      .map((submenu) => (
        <div key={submenu.MenuCode} style={{ paddingLeft: '20px' }}>
          {submenu.MenuName}
        </div>
      ));
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const togglePopup = async () => {
    setIsProfilePopupVisible((prev) => !prev);
    
    if (!isProfilePopupVisible && userDetails) {
      try {
        const response = await axios.post('/api/user/details', {
          userID: userDetails.UserID,
        });
        setUserDetails(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Error fetching user details');
      }
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('userDetails');
    setUserDetails(null);
    navigate('/LoginSelect');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profilePopupRef.current && !profilePopupRef.current.contains(event.target)) {
        setIsProfilePopupVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='Container'>
      <nav className='Navbar'>
        <span className="Menu-View-list" onClick={toggleSidebar}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>
        </span>
        <div className='Funscholar-Logo'>
          <img src="https://funscholar.com/wp-content/uploads/2021/09/logo.png" alt="Funscholar Logo" />
        </div>
        <h2 className='navbar-heading'>{heading}</h2>
        <div className="profile-container" onClick={togglePopup}>
          <div className="profile-icon">
            <i className="bi bi-person-circle" style={{ fontSize: '30px', marginRight: '50px' }}></i>
          </div>
        </div>
        {/* Profile Popup */}
        {isProfilePopupVisible && userDetails && (
          <div className="profile-popup" ref={profilePopupRef}>
            <p><strong>Name:</strong> {userDetails.UserName}</p>
            <p><strong>Mobile Number:</strong> {userDetails.Mobile}</p>
            <p><strong>Email ID:</strong> {userDetails.EmailID}</p>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
      </nav>
      <div className="container-fluid">
        {isSidebarVisible && (
          <div className="sidebar">
            {renderMenuItems()}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;



// import React, { useEffect, useState } from 'react';

// const AdminDashboard = () => {
//   const [menuData, setMenuData] = useState([]);
//   const [expandedMenu, setExpandedMenu] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchMenuData = async () => {
//       try {
//         const response = await fetch('/menus');
//         if (!response.ok) throw new Error('Network response was not ok');
//         const data = await response.json();
//         setMenuData(data);
//       } catch (error) {
//         setError('Error fetching menu: ' + error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchMenuData();
//   }, []);

//   const toggleSubmenu = (menuCode) => {
//     setExpandedMenu((prev) => (prev === menuCode ? null : menuCode));
//   };

//   const renderMenuItems = () => {
//     return menuData
//       .filter((item) => item.ParentCode === 0.00000)
//       .map((menu) => (
//         <div key={menu.MenuCode}>
//           <div
//             onClick={() => toggleSubmenu(menu.MenuCode)}
//             style={{ cursor: 'pointer', fontWeight: 'bold' }}
//             role="button"
//             tabIndex={0}
//             onKeyPress={(e) => e.key === 'Enter' && toggleSubmenu(menu.MenuCode)}
//           >
//             {menu.MenuName}
//           </div>
//           {expandedMenu === menu.MenuCode && renderSubmenuItems(menu.MenuCode)}
//         </div>
//       ));
//   };

//   const renderSubmenuItems = (parentMenuCode) => {
//     return menuData
//       .filter((submenu) => submenu.ParentCode === parentMenuCode)
//       .map((submenu) => (
//         <div key={submenu.MenuCode} style={{ paddingLeft: '20px' }}>
//           {submenu.MenuName}
//         </div>
//       ));
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;

//   return <div>{renderMenuItems()}</div>;
// };

// export default AdminDashboard;

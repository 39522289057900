import React, { useEffect, useState } from 'react';
import '../ALL Css/ALL Dashboard CSS/VideoPlayer.css'; 

const VideoPlayer = () => {
  const [categories, setCategories] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [selectedCategory, setSelectedCategory] = useState(''); // State to hold the selected category

  useEffect(() => {
    const fetchCategories = async () => {
        try {
          const response = await fetch('http://localhost:5001/api/videos', {
            method: 'POST', // Change to POST
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ action: 'getCategories' }) // Pass action or any required data
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          setCategories(data);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };

    fetchCategories();
  }, []);

  const handleCategoryClick = async (category) => {
    const response = await fetch(`http://localhost:5001/api/videos/${category}`);
    const data = await response.json();
    setVideos(data);
    setSelectedVideo(null); 
    setSelectedCategory(category); 
  };

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    setIsModalOpen(true); 
  };

  const closeModal = () => {
    setIsModalOpen(false); 
    setSelectedVideo(null); 
  };

  return (
    <div className="video-player-container">
      <div className="menu">
        <h2>Chapter</h2>
        <ul>
          {categories.map((category) => (
            <li key={category.category} onClick={() => handleCategoryClick(category.category)}>
              {category.category}
            </li>
          ))}
        </ul>
      </div>

      <div className="submenu">
        {videos.length > 0 && (
          <>
            <h5>Subchapter for : {selectedCategory}</h5> 
            <ul>
              {videos.map((video) => (
                <li key={video.videoid} onClick={() => handleVideoSelect(video)}>
                  {video.name}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      {isModalOpen && (
        <div className="Modal-Overlay" onClick={closeModal}>
          <div className="Modal-Content" onClick={(e) => e.stopPropagation()}> 
            <iframe
              src={selectedVideo.vimeo_link_2}
              width="560"
              height="315"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title={selectedVideo.name}
            ></iframe>
            <h6>Playing: {selectedVideo.name}</h6>
            <button className="close-modal" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;

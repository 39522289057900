import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../ALL Css/Registration.css';

const RegistrationForm = () => {
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [password, setPassword] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const controlSubmit = async (e) => {
    e.preventDefault();

    const user = { email, mobile, whatsapp, password, createdBy };

    try {
      const response = await axios.post('/register', user);
      
      if (response.status === 200) {
        setSuccess(`Registration successful. User ID: ${response.data.userId}`);
        setTimeout(() => navigate('/loginselect'), 1000);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Something went wrong. Please try again.');
    }
  };

  return (
    <div className="register">
      <div className="register-left">
        <div className="register-backgroundimg" style={{ backgroundImage: 'url("https://as1.ftcdn.net/v2/jpg/04/62/53/34/1000_F_462533410_e5vMFOmxBGFkB7wDWQDEWyYXDNvvNwqn.jpg")', height: '100%', width: '100%' }}></div>
      </div>
      <div className="register-right">
        <form className='registration-form' onSubmit={controlSubmit}>
          <h1>Register</h1>
          {error && <p style={{ color: 'red' }} className="error-message">{error}</p>}
          {success && <p style={{ color: 'blue' }} className="success-message">{success}</p>}
          
          <div>
            <label>Email:</label>
            <input type="email" value={email} autoComplete="off" onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div>
            <label>Mobile:</label>
            <input type="text" value={mobile} autoComplete="off" onChange={(e) => setMobile(e.target.value)} required />
          </div>
          <div>
            <label>WhatsApp:</label>
            <input type="text" value={whatsapp} autoComplete="off" onChange={(e) => setWhatsapp(e.target.value)} required />
          </div>
          <div>
            <label>Password:</label>
            <div className="password-input-wrapper">
              <input
                name="Password"
                value={password}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                id="Password"
                autoComplete="new-password"
                required
              />
              <svg onClick={togglePasswordVisibility} viewBox="0 0 576 512" height="20" xmlns="http://www.w3.org/2000/svg">
                <path d="M288 32C207.2 32 142.5 68.8 95.4 112.6C48.6 156 17.3 208 2.5 243.7C-.8 251.6-.8 259.4 2.5 267.3C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480C368.8 480 433.5 443.2 480.6 399.4C527.4 356 558.7 304 573.5 267.3C576.8 259.4 576.8 251.6 573.5 243.7C558.7 208 527.4 156 480.6 112.6C433.5 68.8 368.8 32 288 32zM144 256A144 144 0 1 1 432 256A144 144 0 1 1 144 256zM288 192C323.3 192 352 220.7 352 256C352 291.3 323.3 320 288 320C252.7 320 224 291.3 224 256C224 220.7 252.7 192 288 192z" />
              </svg>
            </div>
          </div>
          <div>
            <label>Created By:</label>
            <input type="text" value={createdBy} autoComplete="off" onChange={(e) => setCreatedBy(e.target.value)} required />
          </div>
          <button type="submit" className="register-btn">Register</button>
          <div className="register-link">
            <a href="/passwordreset">Lost Your Password?</a>
          </div>
          <div className="register-button-wrapper">
            <div className="register-text">
              <span>Already have an account?</span>
              <a href="/loginselect" className="register-btn1">Login</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;

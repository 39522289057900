import React from 'react'
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import '../../../ALL Css/ALL Dashboard CSS/AdminDashboard.css'

const initialCentreState = {
  CentreName: '',
  Add1: '',
  Add2: '',
  City: '',
  State: '',
  Country: '',
  Pincode: '',
  Mobile: '',
  EmailID: ''
  };
const Centre = () => {
    const [centreDetails, setCentreDetails] = useState([]);
    const [newCentre, setNewCentre] = useState(initialCentreState);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCentre, setSelectedCentre] = useState(null);
    const searchInputRef = useRef(null);

    useEffect(() => {
         const fetchCentres = async () => {
          try {
            const response = await axios.post('http://localhost:5000/api/centres');
            setCentreDetails(response.data);
          } catch (error) {
            console.error('Error fetching centres:', error);
            setErrorMessage('Error fetching centres');
          } finally {
            setLoading(false);
          }
        };
    
        fetchCentres();
      }, []);

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCentre((prev) => ({ ...prev, [name]: value }));
      };

      const handleSaveCentre = async () => {
        const url = isEdit
          ? `http://localhost:5000/centres/${selectedCentre}`
          : 'http://localhost:5000/centres';
    
        try {
          const response = await axios({
            method: isEdit ? 'PUT' : 'POST',
            url: url,
            data: newCentre,
          });
          if (isEdit) {
            setCentreDetails((prev) =>
              prev.map((centre) => (centre.CentreCode === selectedCentre ? response.data : centre))
            );
          } else {
            setCentreDetails((prev) => [...prev, response.data]);
          }
          setShowForm(false);
          setNewCentre(initialCentreState);
        } catch (error) {
          setErrorMessage('Error saving centre');
        }
      };
    
      const handleEdit = (centre) => {
        setNewCentre(centre);
        setSelectedCentre(centre.CentreCode);
        setIsEdit(true);
        setShowForm(true);
      };

      const handleAddNewCentre = () => {
        setShowForm(true);
        setNewCentre(initialCentreState);
        setIsEdit(false); 
    };
    
    
      const handleDelete = async (centreCode) => {
        try {
          await axios.delete(`http://localhost:5000/api/centres/${centreCode}`);
          setCentreDetails((prev) =>
            prev.filter((centre) => centre.CentreCode !== centreCode)
          );
        } catch (error) {
          setErrorMessage('Error deleting centre');
        }
      };
    
      const handleEntriesChange = (value) => {
        setEntriesPerPage(value);
        setCurrentPage(1); 
      };
    
      const exportToCSV = () => {
        const headers = ['CentreName', 'Address', 'City', 'State', 'Country', 'Pincode', 'Mobile', 'EmailID'];
        const csvRows = [
          headers.join(','), 
          ...centreDetails.map((centre) =>
            [
              `"${centre.Name || ''}"`, 
              `"${centre.Addddress || ''}"`, 
              `"${centre.City || ''}"`, 
              `"${centre.State || ''}"`, 
              `"${centre.Country || ''}"`, 
              `"${centre.Pincode || ''}"`, 
              `"${centre.Mobile || ''}"`, 
              `"${centre.Email || ''}"`
            ].join(',')
          ),
        ];
    
        const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Centres.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
    
    
    
      const filteredCentreDetails = centreDetails.filter((centre) =>
        ['CentreName', 'Address','City', 'State', 'Mobile', 'Country', 'Pincode', 'EmailID'].some(
          (field) =>
            centre[field]?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    
    
      const indexOfLastEntry = currentPage * entriesPerPage;
      const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
      const currentCentres = filteredCentreDetails.slice(indexOfFirstEntry, indexOfLastEntry);
    
      const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredCentreDetails.length / entriesPerPage)) {
          setCurrentPage((prev) => prev + 1);
        }
      };
      
      const handlePrevPage = () => {
        if (currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
        }
      };
    
    
      const handleSearchClick = () => {
        setIsSearchExpanded(true);
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      };
    
      const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setIsSearchExpanded(false);
        }
      };
    
    
  return (
    <div>
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
              <div className="All-Search-bar">
                <div className='Search-bar' onBlur={handleBlur}>
                  {isSearchExpanded && (
                    <input
                      ref={searchInputRef}
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      className={`search-input ${isSearchExpanded ? 'search-input-expanded' : ''}`}
                      onChange={(e) => setSearchQuery(e.target.value)} />
                  )}
                  <button title='Search' onClick={handleSearchClick} className="search-btn">
                    <i className="bi bi-search"></i>
                  </button>
                  <button title="Add New Details" className="btn btn-primary my-3" onClick={handleAddNewCentre}>
                    <i className="bi bi-plus-circle"></i>
                  </button>
                  <button title="Export to Excel" className="btn-secondarymy-3" style={{ border: "none" }} onClick={exportToCSV}>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                      <rect width="16" height="9" x="28" y="15" fill="#21a366"></rect><path fill="#185c37" d="M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z"></path><rect width="16" height="9" x="28" y="24" fill="#107c42"></rect><rect width="16" height="9" x="12" y="15" fill="#3fa071"></rect><path fill="#33c481" d="M42,6H28v9h16V8C44,6.895,43.105,6,42,6z"></path><path fill="#21a366" d="M14,6h14v9H12V8C12,6.895,12.895,6,14,6z"></path><path d="M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z" opacity=".05"></path><path d="M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425	C25.333,34.603,23.936,36,22.213,36z" opacity=".07"></path><path d="M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z" opacity=".09"></path><linearGradient id="flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1" x1="4.725" x2="23.055" y1="14.725" y2="33.055" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#18884f"></stop><stop offset="1" stop-color="#0b6731"></stop></linearGradient><path fill="url(#flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1)" d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z"></path><path fill="#fff" d="M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526	l3.193-5.018L9.807,19z"></path>
                    </svg>
                  </button>
                </div>
                <div className="col-md-6">
                  <label className="mr-2">Show</label>
                  <select
                    name="entries"
                    className="form-control form-control-sm w-auto"
                    onChange={(e) => handleEntriesChange(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <label className="ml-2">Entries</label>
                </div>
              </div>


              {loading ? (
                <p>Loading centres...</p>
              ) : (

                <table className="rounded-table">
                  <thead>
                    <tr>
                      <th>Centre Name</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Country</th>
                      <th>Pincode</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCentres.map((centre) => (
                      <tr key={centre.CentreCode}>
                        <td>{centre.Name}</td>
                        <td>{centre.Address}</td>
                        <td>{centre.City}</td>
                        <td>{centre.State}</td>
                        <td>{centre.Country}</td>
                        <td>{centre.Pincode}</td>
                        <td>{centre.Mobile}</td>
                        <td>{centre.Email}</td>
                        <td>
                          <button className="btn btn-secondary" onClick={() => handleEdit(centre)}>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24">
                              <path d="M 16.9375 1.0625 L 3.875 14.125 L 1.0742188 22.925781 L 9.875 20.125 L 22.9375 7.0625 C 22.9375 7.0625 22.8375 4.9615 20.9375 3.0625 C 19.0375 1.1625 16.9375 1.0625 16.9375 1.0625 z M 17.3125 2.6875 C 18.3845 2.8915 19.237984 3.3456094 19.896484 4.0214844 C 20.554984 4.6973594 21.0185 5.595 21.3125 6.6875 L 19.5 8.5 L 15.5 4.5 L 16.9375 3.0625 L 17.3125 2.6875 z M 4.9785156 15.126953 C 4.990338 15.129931 6.1809555 15.430955 7.375 16.625 C 8.675 17.825 8.875 18.925781 8.875 18.925781 L 8.9179688 18.976562 L 5.3691406 20.119141 L 3.8730469 18.623047 L 4.9785156 15.126953 z"></path>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
                <span>{currentPage} of {Math.ceil(filteredCentreDetails.length / entriesPerPage)}</span>
                <button onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredCentreDetails.length / entriesPerPage)}>Next</button>
              </div>
          
          
          {showForm && (
            <div className="modal fade show" style={{ display: 'block' }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {isEdit ? 'Edit Centre' : 'Add New Centre'}</h5>

                    {isEdit && (
                      <button
                        onClick={() => handleDelete(selectedCentre)}
                        class="bin-button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 39 7"
                          class="bin-top"
                        >
                          <line stroke-width="4" stroke="white" y2="5" x2="39" y1="5"></line>
                          <line
                            stroke-width="3"
                            stroke="white"
                            y2="1.5"
                            x2="26.0357"
                            y1="1.5"
                            x1="12"
                          ></line>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 33 39"
                          class="bin-bottom"
                        >
                          <mask fill="white" id="path-1-inside-1_8_19">
                            <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"
                            ></path>
                          </mask>
                          <path
                            mask="url(#path-1-inside-1_8_19)"
                            fill="white"
                            d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                          ></path>
                          <path stroke-width="4" stroke="white" d="M12 6L12 29"></path>
                          <path stroke-width="4" stroke="white" d="M21 6V29"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 89 80"
                          class="garbage"
                        >
                          <path
                            fill="white"
                            d="M20.5 10.5L37.5 15.5L42.5 11.5L51.5 12.5L68.75 0L72 11.5L79.5 12.5H88.5L87 22L68.75 31.5L75.5066 25L86 26L87 35.5L77.5 48L70.5 49.5L80 50L77.5 71.5L63.5 58.5L53.5 68.5L65.5 70.5L45.5 73L35.5 79.5L28 67L16 63L12 51.5L0 48L16 25L22.5 17L20.5 10.5Z"
                          ></path>
                        </svg>
                      </button>
                    )}
                    <button type="button" className="Close-button" onClick={() => setShowForm(false)}>
                      <span>&times;</span>
                    </button>

                  </div>
                  <form className='New-Form' onSubmit={(e) => { e.preventDefault(); handleSaveCentre(); }}>
                    <div className="modal-body">
                      {Object.keys(newCentre).map((key) => {
                        if (key === 'MobileVerified' || key === 'EmailVerified') {
                          return (
                            <div className="form-group form-check" key={key}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name={key}
                                checked={newCentre[key]}
                                onChange={handleInputChange} />
                              <label className="form-check-label">{key === 'MobileVerified' ? 'Mobile Verified' : 'Email Verified'}</label>
                            </div>
                          );
                        }
                        return (
                          <div className="form-group" key={key}>
                            <input
                              type={key === 'EmailID' ? 'email' : 'text'}
                              className="form-control"
                              name={key}
                              placeholder={key.replace(/([A-Z])/g, ' $1')}
                              value={newCentre[key]}
                              onChange={handleInputChange}
                              required={key !== 'Add2'} />
                          </div>
                        );
                      })}
                    </div>
                    <div className="modal-footer">
                    <button type="submit" className="btn btn-success">
              {isEdit ? 'Update Centre' : 'Add Centre'}
            </button>
                      <button onClick={() => setShowForm(false)} type="button" className="btn btn-secondary" >Cancel</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
    </div>
  )
};

export default Centre;